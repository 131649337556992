.transparent-overlay {
  pointer-events: none;

  * {
    pointer-events: none;
  }

  &.cdk-overlay-backdrop {
    opacity: 0 !important;
  }
}
