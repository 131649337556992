@import 'variables';

.ant-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 5px;
  }

  &.help-content {
    .ant-popover-inner {
      &-content {
        max-width: 275px;
        color: $secondary-grey;
        font-size: 12px;

        ul {
          margin: 5px 0;
          list-style: disc;

          li {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
