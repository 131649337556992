@import 'variables';

.secondary-tabs {
  display: flex;
  align-items: center;
  padding: 10px 15px;

  &__tab {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid $gray-2;
    border-radius: 20px;
    color: $secondary-grey;
    cursor: pointer;
    font-weight: 600;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &.active {
      background: $gray-2;
      color: $primary-black;
    }
  }
}
