@import 'variables';

.ant-select {
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;

  &-selector {
    height: 50px !important;
    padding: 8px 15px !important;
    border: 1px solid $field-border !important;
    border-radius: 10px !important;
    background-color: $field-bg !important;
    box-shadow: unset !important;
  }

  &-selection-placeholder {
    color: $secondary-text-light;
  }

  &-selection-search {
    top: 0 !important;
    right: unset !important;
    left: 0 !important;
    width: 100%;
    padding: 8px 15px;
  }

  &-item {
    border-radius: 5px;

    &:hover {
      background-color: $black-5 !important;
    }
  }

  &-item-option-active {
    background-color: unset !important;
  }

  &-item-option-selected {
    background-color: $black-5 !important;
  }
}

.ant-select-dropdown {
  border-radius: 5px;
}

.ant-select-item-empty {
  padding: 0;
}

.fixed-dropdown {
  min-width: 200px;
}

.custom-options {
  .ant-select-item {
    padding: 0;
  }
}
