@import 'variables';

.ant-picker-range {
  padding: 10px 15px;
  border: 1px solid $gray-2;
  border-radius: 10px;
  background: $gray-1;
  box-shadow: unset;

  .ant-picker-input {
    min-width: calc(50% - 16px);

    input {
      &::placeholder {
        color: $secondary-text-light;
      }
    }
  }

  .ant-picker-separator {
    color: $gray-5;
  }

  .ant-picker-suffix {
    display: none;
  }

  &:hover {
    border-color: $gray-2;
  }
}

.ant-picker-panels {
  position: relative;

  .ant-picker-panel {
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
      visibility: visible !important;
    }

    &:last-child {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
  }
}
