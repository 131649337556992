/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'icons';
@import 'reset';
@import 'font';
@import 'block-container';
@import 'notification-block';
@import 'fields';
@import 'block-actions';
@import 'secondary-tabs';
@import 'occupations-tags';
@import 'tags';
@import 'overlay';
@import 'scrollbar';
@import 'skeleton';
@import 'ng-zorro/switch';
@import 'ng-zorro/select';
@import 'ng-zorro/dialog';
@import 'ng-zorro/dropdown';
@import 'ng-zorro/popover';
@import 'ng-zorro/notification';
@import 'ng-zorro/tabs';
@import 'ng-zorro/range-picker';
@import 'ng-zorro/time-picker';

// #cookiescript_accept,
// #cookiescript_save {
//   background-color: $accent-main !important;
//   color: #fff !important;
// }

/* stylelint-disable */
#cookiescript_badge {
  display: none !important;
}
/* stylelint-enable */

body {
  background: $gray-1;
  color: $primary-black;
  font-family: Manrope, sans-serif;
  font-size: 14px;
}

* {
  box-sizing: border-box;
  font-family: inherit;
}

button {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

img {
  max-width: 100%;
}

img.cover,
video.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img.contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.skeleton-placeholder {
  @include skeleton-content;

  &.no-margin {
    margin-bottom: 0;
  }

  &.white {
    background: $white;
  }
}

a.dating-link {
  margin: 0 5px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
