@import 'variables';
@import 'responsibe-breakpoint-mixins';

.block-container {
  padding: 25px;
  border-radius: 10px;
  background: $white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 800;

    &-info {
      span {
        display: block;
        margin-bottom: 10px;
      }

      div {
        font-size: 14px;
      }
    }

    &:first-child:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-bottom: 20px;
    color: $primary-black;
    font-size: 14px;
    font-weight: 700;
  }

  &__label {
    margin-bottom: 10px;
    color: $gray-6;
    font-size: 12px;
    font-weight: 500;
  }

  &__actions {
    margin-left: auto;
  }

  &__separator {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background: $gray-2;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
