$white: #fff;
$white-80: rgb(255 255 255 / 80%);
$white-60: rgb(255 255 255 / 60%);
$white-40: rgb(255 255 255 / 40%);
$white-20: rgb(255 255 255 / 20%);
$white-10: rgb(255 255 255 / 10%);
$white-5: rgb(255 255 255 / 5%);
$primary-black: #303942;
$primary-50: rgb(48 57 66 / 50%);
$secondary-grey: #707b86;
$gray-1: #f8f9fb;
$gray-2: #eceff2;
$gray-3: #cdd4db;
$gray-4: #bcc3c9;
$gray-5: #a6adb5;
$gray-6: #949ca4;
$main-gradient-diagonal: linear-gradient(167.96deg, #75147d 0%, #d627e4 100%);
$main-gradient-horizontal: linear-gradient(90deg, #75147d 0%, #d627e4 100%);
$secondary-gradient-diagonal: linear-gradient(313.75deg, #8b1d94 2.1%, #91289a 100%);
$accent-primary-100: #75147d;
$accent-primary-10: rgb(117 20 125 / 10%);
$accent-primary-5: rgb(117 20 125 / 5%);
$accent-secondary: #9d50a3;
$accent-third: #b065b7;
$accent-primary-light: rgb(117 20 125 / 10%);
$premium-diagonal: linear-gradient(127.18deg, #000 0%, #3e3e3e 100%);
$premium-solid: #171717;
$premium-secondary: #171717;
$premium-solid-5: rgb(233 159 85 / 5%);
$diamond-diagonal: linear-gradient(141.72deg, #1bc6ec 4.38%, #6977f9 83.7%);
$diamond-solid: #6978ff;
$violet-diagonal: linear-gradient(313.75deg, #6875e9 2.1%, #9f3fff 100%);
$violet-solid: #825cf4;
$violet-solid-10: rgb(130 92 244 / 8%);
$violet-lighter-diagonal: linear-gradient(313.75deg, #808cff 2.1%, #b367ff 100%);
$violet-lighter-solid: #9471ff;
$blue-100: #2388e5;
$blue-30: rgb(35 136 229 / 30%);
$blue-10: rgb(35 136 229 / 10%);
$blue-5: rgb(35 136 229 / 5%);
$green-100: #6ddf6b;
$green-10: rgb(109 223 107 / 10%);
$warning-100: #ff7236;
$dark-grey: #1c1c1c;
$black-100: #000;
$black-80: rgb(0 0 0 / 80%);
$black-50: rgb(0 0 0 / 50%);
$black-10: rgb(0 0 0 / 10%);
$black-5: rgb(0 0 0 / 5%);

// Additional gradients
$main-gradient-light: linear-gradient(180deg, rgb(117 20 125 / 25%) 0%, #75147d 98.94%);
$blue-gradient: linear-gradient(20deg, $blue-100 0, rgb(35 136 229 / 0%) 100%);
$accent-secondary-gradient: linear-gradient(89.94deg, $accent-secondary 0.04%, rgb(117 20 125 / 0%) 99.94%);
$light-violet-gradient: linear-gradient(313.75deg, rgb(128 140 255 / 10%) 2.1%, rgb(179 103 255 / 10%) 100%);
$light-dark-gradient: linear-gradient(9.1deg, $black-100 6.9%, rgb(0 0 0 / 0%) 93.1%);
$image-gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 30%) 100%);

// Text color variables
$primary-text: $primary-black;
$secondary-text: $secondary-grey;
$secondary-text-light: $gray-6;
$secondary-text-light-2: $gray-4;

// Main accent color
$accent-main: $accent-primary-100;
$error: $accent-secondary;

// Secondary bg
$field-border: $gray-2;
$field-border-focus: $gray-3;
$field-bg: $gray-1;

h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.25;
}

h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 1.25;
}

h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25;
}

h4 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.25;
}
