@import 'variables';

.ant-picker {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid $gray-2;
  border-radius: 10px;
  background: $gray-1;
  box-shadow: unset;

  .ant-picker-input {
    input {
      &::placeholder {
        color: $secondary-text-light;
      }
    }
  }

  &:hover {
    border-color: $gray-2;
  }
}

.ant-picker-time-panel {
  .ant-picker-footer {
    display: none;
  }
}
