@import 'variables';
@import 'responsibe-breakpoint-mixins';

.occupations-tags {
  display: flex;
  max-width: 550px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  &__title {
    color: $secondary-grey;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }

  &__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
  }

  &__item {
    position: relative;
    height: fit-content;
    padding: 8px 15px;
    border: 1px solid $field-border;
    border-radius: 15px;
    margin: 5px;
    background-color: $field-bg;
    color: $secondary-text;
    cursor: pointer;
    font-weight: 600;
    line-height: 14px;

    &.active {
      border: 1px solid $accent-main;
      background-color: $white;
      color: $accent-main;
    }
  }

  &__item-index {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $accent-main;
    color: $white;
    font-size: 12px;
  }
}
