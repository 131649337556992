@import 'responsibe-breakpoint-mixins';

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal {
    top: unset;
    width: fit-content !important;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;

    .ant-modal-content {
      border-radius: 0;
      background: transparent;
      box-shadow: none;

      .ant-modal-body {
        padding: 0;
      }
    }

    @include tablet {
      width: 100% !important;
    }
  }

  @include tablet {
    align-items: flex-end;
  }
}
