@import 'variables';

.date-tag {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 15px;
  background: $accent-primary-10;
  color: $accent-primary-100;
  column-gap: 4px;
  text-transform: capitalize;

  [nz-icon] {
    color: $accent-primary-100 !important;
  }
}
