@import 'variables';
@import 'responsibe-breakpoint-mixins';

[nztype='ng-zorro:close'] {
  cursor: pointer;
  font-size: 30px;

  &:hover {
    color: $accent-main;
  }
}

.anticon {
  color: inherit;

  &.has-hover {
    &:hover {
      color: $accent-main;
    }
  }

  &.fz-12 {
    font-size: 12px;
  }

  &.fz-14 {
    font-size: 16px;
  }

  &.fz-16 {
    font-size: 16px;
  }

  &.fz-18 {
    font-size: 18px;
  }

  &.fz-20 {
    font-size: 20px;
  }

  &.fz-22 {
    font-size: 22px;
  }

  &.fz-24 {
    font-size: 24px;
  }

  &.fz-26 {
    font-size: 26px;
  }

  &.fz-28 {
    font-size: 28px;
  }

  &.mr-5 {
    margin-right: 5px;
  }

  &.mr-10 {
    margin-right: 10px;
  }

  &.ml-5 {
    margin-left: 5px;
  }

  &.ml-10 {
    margin-left: 10px;
  }
}

.premium-icon {
  color: $premium-solid;
}

.subscription-icon {
  color: $violet-solid;
}

.verified-icon {
  color: $blue-100;
}

.online-icon {
  color: $green-100;
  font-size: 5px !important;
}
