:root {
  height: fit-content;
  touch-action: manipulation;

  body {
    overflow: initial !important;

    &.no-scroll {
      overflow: hidden !important;
    }
  }
}

.scroll-unblock {
  position: unset;
  overflow-y: scroll !important;
}
