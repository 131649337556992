@import 'variables';
@import 'responsibe-breakpoint-mixins';

.notification-block {
  display: flex;
  max-width: 420px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  .icon-container {
    display: flex;
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-top: 80px;
    background: $gray-1;
  }

  .info-container {
    margin-top: 40px;
  }

  .additional-info {
    padding: 10px;
    border: 1px solid $gray-2;
    border-radius: 10px;
    margin-top: 30px;
    color: $secondary-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  dt-button {
    width: 100%;
    margin-top: 40px;
  }
}
