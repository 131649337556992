@import 'variables';

.ant-dropdown-menu {
  overflow: hidden;
  width: 210px;
  padding: 10px;
  border-radius: 10px;

  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 5px;
    background: $white;
    font-weight: 600;
    transition: none;

    [nz-icon] {
      margin-right: 7px;
      color: $gray-5;
      font-size: 20px;
    }
  }

  .ant-menu-title-content {
    display: flex;
    align-items: center;
    transition: none;
  }

  .ant-dropdown-menu-item.active,
  .ant-dropdown-menu-item:hover {
    color: $accent-primary-100;

    i:not(.hover-disabled) {
      svg {
        path {
          fill: $accent-primary-100;
        }
      }
    }
  }
}
