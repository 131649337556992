@import 'variables';

@mixin phone-only {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1599px) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin container-box {
  @media (max-width: 1445px) {
    @content;
  }
}

@mixin container {
  width: 1300px;
  max-width: 90%;
  margin: 0 auto;
}

@mixin skeleton-content {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 8px;
  background: $gray-1;
  text-indent: 100%;
  white-space: nowrap;
}

@mixin skeleton-circle {
  overflow: hidden;
  border-radius: 88px;
  background: $gray-1;
  text-indent: 100%;
  white-space: nowrap;
}
