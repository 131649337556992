@import 'variables';
@import 'responsibe-breakpoint-mixins';

.field-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: $secondary-text-light;
  font-size: 12px;
  font-weight: 600;

  &__reset {
    color: $gray-3;
    cursor: default;
    font-weight: 500;

    [nz-icon] {
      margin-right: 5px;
      color: $gray-3;
      transform: scaleX(-1);
    }

    &_active {
      color: $accent-main;
      cursor: pointer;

      [nz-icon] {
        color: $accent-main;
      }
    }
  }
}

.help-item {
  color: $gray-3;
  cursor: pointer;
}
