@import 'variables';

.filters-actions {
  display: flex;
  align-items: center;
  color: $gray-5;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: 0 10px;
    background: $gray-5;
  }

  [nz-icon] {
    margin-left: 10px;
    color: $gray-6;
    cursor: pointer;
    font-size: 18px;
  }

  @include tablet {
    div {
      display: none;
    }
  }
}
