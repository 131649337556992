.ant-notification-notice {
  display: flex;
  width: 260px;
  align-items: center;
  border-radius: 10px;

  .ant-notification-notice-content {
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ant-notification-notice-message,
      .ant-notification-notice-description {
        margin-bottom: 0;
        color: white;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }

    .ant-notification-notice-with-icon {
      .ant-notification-notice-icon {
        width: 20px;
        height: 20px;
        margin-left: 0;
        font-size: unset;
        line-height: unset;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: white;
          }
        }
      }

      .ant-notification-notice-message,
      .ant-notification-notice-description {
        margin-left: 30px;
      }
    }
  }

  .ant-notification-notice-close {
    top: unset;

    .ant-notification-notice-close-x {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
}
