.ant-switch {
  width: 50px;
  height: 30px;

  .ant-switch-handle {
    top: 1px;
    width: 28px;
    height: 28px;

    &::before {
      border-radius: 15px;
    }
  }

  &.ant-switch-checked {
    background: $green-100;

    .ant-switch-handle {
      left: calc(100% - 27px - 2px) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-click-animating-node {
    display: none;
  }

  &:focus {
    box-shadow: none;
  }
}
